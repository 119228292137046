.avenger {
  position: relative;
  border-radius: 10px;
  padding: 15px 20px;
  padding-bottom: 0;
  background-color: lightpink;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 15px;
}

.delete {
  content: "";
  position: absolute;
  top: 10px;
  right: 10px;
  height: 50px;
  width: 50px;
  background-color: #443d3d;
  border-radius: 50%;
  display: grid;
  place-items: center;
}

.delete-button {
  cursor: pointer;
  color: #d3d3d3;
  width: 40px;
  height: 40px;
}

.delete-button:hover {
  color: #fff;
}

.avenger-image {
  height: 80%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.avenger-name {
  color: #443d3d;
  font-weight: 550;
  padding: 0;
  margin: 0;
}

.avenger-charname {
  padding: 0;
  margin: 0;
  color: #443d3d;
  font-weight: 450;
}
